<template>
  <v-row>
    <v-col v-if="!onlyInfo" cols="12">
      <v-switch
        v-model="useCustomCurrencies"
        :label="$t('company.use.custom.currencies')"
      />
    </v-col>

    <v-col cols="12">
      <v-data-table
        :key="sDataTableKey"
        :headers="headers"
        :items="items"
        :loading="loading"
        class="dt-listing-simple"
        hide-default-footer
      >
        <template #[`item.name`]="{ item }">
          <currency-preview v-if="item.rate_value === 1" :item="item" />
          <currency-rate-history-dialog v-else :currency="item.code" />
        </template>

        <template #[`item.company_rate`]="{ item }">
          <company-currency-rate-field
            :auto-save="autoSave"
            :company-id="obCompany.id"
            :currencies="obCurrencies"
            :disabled="item.is_default"
            :readonly="onlyInfo"
            :value="item"
            @update:item="onUpdateRate"
          />
        </template>

        <template #[`item.round`]="{ item }">
          <currency-rate-round-select
            :company-id="obCompany.id"
            :currency="item"
          />
        </template>

        <template #[`item.code_account`]="{ item }">
          <company-currency-account-code
            :company-id="obCompany.id"
            :value="item"
          />
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { AppModule } from "@/store/app";
import type {
  CompanySettingsData,
  CurrencyRate,
} from "@planetadeleste/vue-mc-gw";
import {
  Company,
  CompanySettings,
  CurrencyRates,
} from "@planetadeleste/vue-mc-gw";
import type {
  CurrencyCollection,
  CurrencyData,
} from "@planetadeleste/vue-mc-shopaholic";
import { Component, Mixins, Prop, VModel } from "vue-property-decorator";
import type { DataTableHeader } from "@/mixins/DataTableMixin";
import DataTableMixin from "@/mixins/DataTableMixin";
import { EventBus } from "@/services/event-bus";
import CurrencyPreview from "@/modules/currencies/components/CurrencyPreview.vue";
import CompanyCurrencyRateField from "@/modules/companies/components/CompanyCurrencyRateField.vue";
import CurrencyRateRoundSelect from "@/modules/currencyrates/components/CurrencyRateRoundSelect.vue";
import { filter } from "lodash";
import CurrencyRateHistoryDialog from "@/modules/currencyrates/components/CurrencyRateHistoryDialog.vue";
import CompanyCurrencyAccountCode from "@/modules/companies/components/CompanyCurrencyAccountCode.vue";

@Component({
  components: {
    CompanyCurrencyAccountCode,
    CurrencyRateHistoryDialog,
    CurrencyPreview,
    CompanyCurrencyRateField,
    CurrencyRateRoundSelect,
  },
})
export default class CompanyCurrenciesRates extends Mixins(DataTableMixin) {
  @VModel({ type: Object, default: () => new Company() })
  obCompany!: Company;
  @Prop({ type: Boolean, default: true }) readonly autoSave!: boolean;
  @Prop(Boolean) readonly onlyInfo!: boolean;

  obCurrencies: CurrencyRates = new CurrencyRates();
  loading = false;
  headers: DataTableHeader[] = [
    { text: "name", value: "name", sortable: false },
    { text: "code", value: "code", sortable: false },
    { text: "symbol", value: "symbol", sortable: false },
    { text: "bcu.rate", value: "rate", sortable: false },
    { text: "company.rate", value: "company_rate", sortable: false },
    { text: "currency.round", value: "round", sortable: false },
    { text: "account.code", value: "code_account", sortable: false },
  ];
  arCurrencyIdList: number[] = [];
  obSettings: CompanySettings = new CompanySettings();

  get currencies(): CurrencyCollection {
    return AppModule.currencies;
  }

  get items(): CurrencyData[] {
    return this.arCurrencyIdList.length && this.currencies.length
      ? filter(
          this.currencies.getModelList() as CurrencyData[],
          (obItem: CurrencyData) => this.arCurrencyIdList.includes(obItem.id)
        )
      : [];
  }

  get useCustomCurrencies(): boolean {
    return this.obSettings
      ? this.obSettings.get("use_custom_currencies", false)
      : false;
  }

  set useCustomCurrencies(bValue: boolean) {
    if (this.obSettings) {
      this.obSettings.set("use_custom_currencies", bValue);
      this.onChangeSettings();
    }
  }

  get disableCustomCurrenciesSwitch(): boolean {
    return this.useCustomCurrencies && this.obCurrencies.length > 0;
  }

  mounted() {
    if (this.onlyInfo) {
      this.delDTHeader(["round", "code_account"]);
    }

    this.mapDTHeaders();
    this.load();
  }

  async onChangeSettings() {
    if (!this.obSettings) {
      return;
    }

    const obResponse = await this.obCompany.updateSettings(
      this.obSettings.attributes as Partial<CompanySettingsData>
    );
    this.obCompany.set("settings", obResponse.getData().data);
    this.updateTableColumns();
  }

  onUpdateRate(obRate: CurrencyRate) {
    this.$emit("update:rate", obRate);
  }

  /**
   * Add/Remove company rates column, based on useCustomCurrencies property value
   */
  updateTableColumns() {
    if (!this.useCustomCurrencies) {
      this.delDTHeader("company_rate");
    } else if (!this.onlyInfo) {
      this.addDTHeader(
        {
          text: "company.rate",
          value: "company_rate",
          sortable: false,
        },
        true
      );
    }
  }

  async load() {
    if (!this.obCompany.id) {
      return;
    }

    // Set company settings
    if (this.obCompany.get("settings")) {
      this.obSettings = new CompanySettings(
        this.obCompany.get("settings") as CompanySettingsData
      );
    }

    this.loading = true;

    const obCurrencyRateCollection = new CurrencyRates();
    // @ts-ignore
    await obCurrencyRateCollection
      .filterBy({ company: this.obCompany.id, rated: 1 })
      .fetch();
    this.obCurrencies = obCurrencyRateCollection;
    EventBus.emit("get.company.currencies", this.obCurrencies);

    // Load company currencies
    const obCompanyCurrencyRateCollection = new CurrencyRates();
    // @ts-ignore
    await obCompanyCurrencyRateCollection
      .filterBy({ companyGrouped: this.obCompany.id })
      .fetch();
    this.arCurrencyIdList = obCompanyCurrencyRateCollection.length
      ? obCompanyCurrencyRateCollection.map("currency_id")
      : [];

    this.updateTableColumns();
    this.loading = false;
  }
}
</script>
